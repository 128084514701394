import { useCallback, useEffect, useState } from 'react';
import { useStaffPreview } from 'hooks/customer';
import { isDateInRange } from '../util/date';
import config from '../util/load-config';
import { PermanentShopEnum } from 'types/types';

const toPermanentShopKey = (shop: string) =>
  shop.replace(/-([a-z])/g, (_, char) => char.toUpperCase());

type ShopId = `${PermanentShopEnum}`;

// all new permanent shop tabs to be stored here
const NEW_TABS: ShopId[] = [PermanentShopEnum.EXTRA_TIME_DEALS];

const useShowNewTab = () => {
  const [staffPreviewDate, setStaffPreviewDate] = useState<
    string | undefined
  >();

  useStaffPreview(({ date }) => setStaffPreviewDate(date));

  const checkDateRangeWithPreview = useCallback(
    (shop: ShopId) => {
      const dateConfig = config[toPermanentShopKey(shop)];

      if (dateConfig) {
        const { animationStartDate, animationEndDate } = dateConfig;
        return isDateInRange(
          animationStartDate,
          animationEndDate,
          staffPreviewDate
        );
      }

      // fallback: show badge
      return true;
    },
    [staffPreviewDate]
  );

  const showNewTab = useCallback(
    (shop?: ShopId) =>
      shop && NEW_TABS.includes(shop) && checkDateRangeWithPreview(shop),
    [checkDateRangeWithPreview]
  );

  return showNewTab;
};

export default useShowNewTab;
