import { ReactNode } from 'react';
import styled from 'components/styled';
import {
  BorderRadiusProps,
  display,
  DisplayProps,
  fontSize,
  FontSizeProps,
  fontWeight,
  FontWeightProps,
  lineHeight,
  LineHeightProps,
  minWidth,
  MinWidthProps,
  space,
  SpaceProps,
  width,
  WidthProps,
  compose,
} from 'styled-system';
import { Container, Flex } from '../box';
import Button from '../button';
import { X as XIcon } from 'react-feather';
import { css, Theme } from '@emotion/react';

interface ToastElementProps {
  variant?: 'info' | 'warning' | 'error' | 'success';
  loading?: boolean;
  isOpen?: boolean;
}

type Props = ToastElementProps &
  SpaceProps &
  FontSizeProps &
  WidthProps &
  FontWeightProps &
  LineHeightProps &
  BorderRadiusProps &
  MinWidthProps &
  DisplayProps;

const loadingStyle = (theme: Theme) => css`
  position: relative;
  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    border: 4px solid ${theme.colors.darkGrey};
    border-left-color: white;
    border-radius: 50%;
    box-sizing: border-box;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
    width: 24px;
    height: 24px;

    @keyframes ld {
      0% {
        transform: rotate(0deg) scale(1);
      }
      50% {
        transform: rotate(180deg) scale(1);
      }
      100% {
        transform: rotate(360deg) scale(1);
      }
    }

    animation: ld 1s linear infinite;
  }
`;

export const variantColors = (variant = 'info', theme: Theme) => {
  let primaryColor, secondaryColor;

  switch (variant) {
    default:
    case 'info':
      primaryColor = theme.colors.info;
      secondaryColor = theme.colors.lightBlue;
      break;
    case 'warning':
      primaryColor = theme.colors.warning;
      secondaryColor = theme.colors.lightOrange;
      break;
    case 'error':
      primaryColor = theme.colors.danger;
      secondaryColor = theme.colors.lightPink;
      break;
    case 'success':
      primaryColor = theme.colors.success;
      secondaryColor = theme.colors.lightLime;
      break;
  }

  return { primaryColor, secondaryColor };
};

export const toastTransitionTimeMs = 800;

const ToastElement = styled.div<Props>`
  ${({ variant = 'info', loading = false, isOpen = false, theme }) => {
    const { primaryColor, secondaryColor } = variantColors(variant, theme);

    return css`
      transition: transform ${toastTransitionTimeMs / 1000}s ease;
      font-family: ${theme.fonts.body};
      background-color: ${secondaryColor};
      color: ${primaryColor};
      font-size: theme.fontSizes[2];
      line-height: 1.2em;
      border-bottom: 1px solid ${primaryColor};
      font-weight: 500;
      outline: none;
      ${loading ? loadingStyle(theme) : ''}
      ${!isOpen &&
      css`
        transform: translate(0, -150%);
      `}
    `;
  }}
  ${compose(space, fontSize, width, fontWeight, lineHeight, minWidth, display)}
`;

type ToastProps = Props & {
  children: ReactNode | string;
  onClose?: () => void;
};

const Toast = ({ children, onClose, variant, ...restProps }: ToastProps) => (
  <ToastElement variant={variant} {...restProps}>
    <Container
      py={2}
      css={css`
        position: relative;
      `}
    >
      <Flex pr={onClose && 5} justifyContent="center">
        {children}
      </Flex>
      {onClose && (
        <Button
          variant="flat"
          onClick={onClose}
          p={0}
          css={css`
            position: absolute;
            top: 5px;
            right: 16px;
          `}
        >
          <XIcon
            css={theme => css`
              color: ${variantColors(variant, theme).primaryColor};
            `}
            size={20}
          />
        </Button>
      )}
    </Container>
  </ToastElement>
);

export default Toast;
