export const zIndices = {
  // Product related
  PRODUCT_READ_MORE: 100,
  PRODUCT_VIDEOS: 110,
  SOLD_OUT_MASK: 120,
  SAVINGS_BADGE: 130,
  CAROUSEL_NAVIGATION: 130,
  MOBILE_ADD_TO_CART: 190,

  // Shop related
  EXPAND_SHOP: 140,

  // Layout
  CHECKOUT_OVERLAY: 150,
  LOADING_OVERLAY: 180,

  //  Third party
  ZENDESK_WIDGET: 225,

  // Navigation
  SCROLL_TO_TOP_BUTTON: 170,
  CATEGORIES_BAR: 200,
  CATEGORIES_BAR_NAVIGATION: 205,
  HEADER_BAR: 210,
  NAV_BAR: 220,

  // Pop Overs
  STICKY_DISMISSAL_TOAST_MESSAGE: 200,
  POPUPS_TOOLTIPS: 230,
  DROPDOWN_MENUS: 240,
  APP_NOTIFICATIONS: 270,

  // Side drawer
  SIDE_DRAWER_OVERLAYS: 280,
  SIDE_DRAWER: 290,

  // Modals/Dialogs
  MODAL_OVERLAY: 310,
  MODALS: 320,

  // Call to Action
  QUICK_APP_DOWNLOAD_BUTTON: 165,
};
